<!-- This is the header bar for the Patient Detail and New Patient pages -->
<template>
    <b-row class="patient-detail-header px-0">
        <b-col xl="8">
            <b-row class="mb-2">
                <b-col class="text-left">
                    <div class="d-inline-flex flex-wrap align-items-center">
                        <div class="mr-4 mb-2 mb-sm-0">
                            <b-icon
                                class="mr-2"
                                @click="goBack"
                                icon="chevron-left"
                                role="button"
                            ></b-icon>

                            <span class="text-uppercase text-caption heavy text-white-lighter">
                                {{ t('patientId') }}
                                <template v-if="!activeCustomer.hidePatientNames">
                                    ({{ t('NameLastFirst') }})
                                </template>
                            </span>
                            <b-icon
                                class="ml-1"
                                @click="modalShow = true"
                                icon="chevron-down"
                                role="button"
                            ></b-icon>
                        </div>

                        <!-- Modal quick patient selector -->
                        <QuickPatientSelection :modalShow.sync="modalShow" />

                        <div class="mr-4 mb-2 mb-sm-0">
                            <span class="dob text-caption heavy text-white-lighter">
                                {{ dob }}
                            </span>
                            <span class="age ml-2 text-white">
                                {{ age }}
                                {{ yearsString }}
                            </span>
                        </div>

                        <span class="text-headline d-inline-flex text-caption heavy text-white">
                            <span class="align-self-center caption mr-1 ml-4">
                                {{ t('surgeon') }}
                            </span>
                            <template>
                                <span class="ml-2">{{ doctorName }}</span>
                            </template>
                        </span>
                    </div>
                </b-col>
            </b-row>
            <b-row class="mb-2">
                <b-col class="mb-n2 d-flex flex-column flex-md-row">
                    <span
                        class="patient-id word-wrap mr-4"
                        :class="{pointer: $route.name == 'PreOpData'}"
                        @click="$route.name == 'PreOpData' ? goBack() : 0"
                    >
                        {{ patientId }}
                    </span>
                    <span
                        v-if="hasPatientPersonalDataPermission"
                        class="patient-name text-white-light word-wrap multiple-line-ellipse"
                    >
                        <template v-if="!activeCustomer.hidePatientNames">
                            {{
                                namesFormatter(
                                    patient && patient.lastName,
                                    patient && patient.firstName
                                )
                            }}
                        </template>
                        <template
                            v-if="patient && patient.gender && patient.gender != Genders.OTHER"
                        >
                            <span class="patient-gender">
                                ({{ t(`gender${patient.gender}`) }})</span
                            >
                        </template>
                    </span>
                </b-col>
            </b-row>
        </b-col>
        <b-col xl="4">
            <b-row class="h-100">
                <b-col sm="7">
                    <div class="caption mb-3">{{ t('patientNotes') }}</div>
                    <p class="word-wrap">
                        {{ patientNote }}
                    </p>
                </b-col>
                <b-col sm="5" class="align-self-center">
                    <slot name="buttons" />
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>
<script>
import {mapGetters} from 'vuex';
import get from 'lodash/get';
import {namesFormatter, surgeonIdNameFormat} from '@/utilities/formatters';
import QuickPatientSelection from '@/views/patientdetails/components/QuickPatientSelection.vue';
import {PERMISSIONS, PERMISSIONS_VALUES} from '@/constants/permissions';
import {checkPermissions} from '@/utilities/permissions';
import {Genders} from '@/constants/patientlist';

export default {
    name: 'PatientDetailsHeaderBar',
    components: {
        QuickPatientSelection,
    },
    props: {
        doctor: {
            default: () => null,
        },
    },
    data() {
        return {
            Genders,
            modalShow: false,
        };
    },
    computed: {
        ...mapGetters({
            age: 'patient/patientAge',
            dob: 'patient/patientDob',
            patient: 'patient/currentPatient',
        }),
        ...mapGetters('user', ['currentUser', 'activeCustomer']),
        ...mapGetters('permissions', ['permissions']),
        hasPatientPersonalDataPermission() {
            return this.checkPermissions({
                [PERMISSIONS.PATIENT_PERSONAL_DATA]: [
                    PERMISSIONS_VALUES.READ_WRITE,
                    PERMISSIONS_VALUES.READ_ONLY,
                ],
            });
        },
        /** Current paitent's doctor */
        doctorName() {
            const doctorInfo = this.doctor || this.patient;
            const doctorName = `
            ${get(doctorInfo, 'firstName', '')} ${get(doctorInfo, 'lastName', '')}
            `;
            const doctorActive = get(doctorInfo, 'active', false);
            let name = doctorInfo
                ? this.surgeonIdNameFormat(
                      doctorInfo.ocosDoctorId,
                      get(doctorInfo, 'doctorName', doctorName),
                      get(doctorInfo, 'doctorActive', doctorActive)
                  )
                : '---';
            return name;
        },

        /** Notes for the current patient */
        patientNote() {
            return this.patient ? this.patient.patientNote : '';
        },

        /** If the patient is enabled (not on the New Patient page), return the "yrs" ending for the age string. */
        yearsString() {
            if (this.dob === '') {
                return '';
            } else {
                return this.t('yrs');
            }
        },

        patientId() {
            return this.patient && this.patient.ocosPatientRef;
        },
    },
    methods: {
        surgeonIdNameFormat,
        namesFormatter,
        checkPermissions(permissionsToCheck) {
            return checkPermissions(
                permissionsToCheck,
                this.permissions,
                this.currentUser.accessPermissions
            );
        },
        goBack() {
            this.$router.push({
                name: this.$route.meta.backPage,
                params: {patientId: this.$route.params.patientId},
            });
        },
    },
};
</script>
<style scoped>
.btn {
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
}
.b-dropdown {
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
}

.patient-id {
    font-weight: bold;
    font-style: normal;
    font-size: 2.25rem;
    align-self: flex-end;
    flex-basis: fit-content;
}

.patient-name {
    font-style: normal;
    font-size: 1.5rem;
    align-self: flex-end;
    flex-basis: fit-content;
}

.patient-gender {
    font-size: 1rem;
    line-height: 1rem;
}

.alpha-warning {
    background-color: #ffffff2e;
    border-color: #0c7e8b;
    color: #ffffffdb;
    padding-left: 10px;
    border-radius: 5px;
}
.multiple-line-ellipse {
    display: -webkit-box;
    overflow-y: auto;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    line-height: 2.1rem;
}
</style>
