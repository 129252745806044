var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"QuickPatientSelectionModal","visible":_vm.modalShow,"dialog-class":"quick-selector-position","content-class":"rounded-0","body-class":"p-0","header-class":"py-1","size":"lg","scrollable":"","hide-footer":""},on:{"hide":_vm.hideSelector}},[_c('b-form',{staticClass:"px-3 pb-3",attrs:{"inline":""},on:{"submit":function($event){$event.preventDefault();return _vm.searchChanged.apply(null, arguments)}}},[_c('label',{staticClass:"sr-only",attrs:{"for":"inline-form-input-name"}},[_vm._v(_vm._s(_vm.t('search')))]),_c('b-input-group',{staticClass:"col mt-2 px-0"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('b-icon',{attrs:{"icon":"search"}})],1),_c('b-form-input',{staticClass:"mr-2 mb-sm-0",attrs:{"type":"search","placeholder":_vm.t('search')},model:{value:(_vm.queryParams.search),callback:function ($$v) {_vm.$set(_vm.queryParams, "search", $$v)},expression:"queryParams.search"}})],1),_c('b-button',{staticClass:"mt-2 mr-sm-2 mb-sm-0 btn-block-xs-only",attrs:{"type":"submit","variant":"primary"}},[_vm._v(_vm._s(_vm.t('search')))]),(
                _vm.checkPermissions(
                    ( _obj = {}, _obj[_vm.PERMISSIONS.CREATE_PATIENT] = _vm.PERMISSIONS_VALUES.ENABLED, _obj ),
                    _vm.permissions,
                    _vm.currentUser.accessPermissions
                )
            )?_c('b-button',{staticClass:"mt-2 main-navigation-button btn-block-xs-only",attrs:{"variant":"primary","to":"/NewPatient"}},[_c('b-icon-plus'),_vm._v(" "+_vm._s(_vm.t('addNewPatient'))+" ")],1):_vm._e()],1),_c('b-table',{staticClass:"quick-patient-selection-table",attrs:{"no-local-sorting":"","borderless":"","items":_vm.patientList,"fields":_vm.fields,"sort-desc":_vm.queryParams.sortOrder === 'desc',"sort-by":_vm.queryParams.sortBy,"tbody-tr-class":_vm.trClasses,"sticky-header":"85%","responsive":"lg","id":"quick-patient-selection-table"},on:{"sort-changed":_vm.sortChanged},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({width: _vm.colWidths[field.key]})})})}},{key:"head(ocosPatientRef)",fn:function(){return [_vm._v(" "+_vm._s(_vm.t('patientId'))+" "),_c('div',{staticClass:"mt-1 column-explanation-text"},[_vm._v(" "+_vm._s(_vm.activeCustomer.hidePatientNames ? ' ' : _vm.t('patientNameLastFirst'))+" ")])]},proxy:true},{key:"head(dateOfBirth)",fn:function(){return [_vm._v(" "+_vm._s(_vm.t('dob'))+" "),_c('div',{staticClass:"mt-1 column-explanation-text"},[_vm._v(_vm._s(_vm.t('dateHint')))])]},proxy:true},{key:"head(surgeon_name)",fn:function(){return [_vm._v(" "+_vm._s(_vm.t('surgeon'))+" "),_c('div',{staticClass:"mt-1 column-explanation-text"},[_vm._v(_vm._s(_vm.t('patientNameLastFirst')))])]},proxy:true},{key:"cell(ocosPatientRef)",fn:function(ref){
            var item = ref.item;
return [(!_vm.isEmpty(item))?_c('router-link',{staticClass:"patient-link text-primary-dark d-block mb-1 text-caption heavy",attrs:{"to":{
                    name: 'PatientDetails',
                    params: {patientId: item.patientId},
                }},nativeOn:{"click":function($event){return _vm.hideSelector.apply(null, arguments)}}},[_c('u',[_vm._v(" "+_vm._s(item.ocosPatientRef))])]):_vm._e(),(!_vm.activeCustomer.hidePatientNames)?_c('p',{staticClass:"text-caption light text-gray-dark mb-0"},[_vm._v(" "+_vm._s(_vm.namesFormatter(item.patientLastName, item.patientFirstName))+" ")]):_vm._e()]}},{key:"cell(dateOfBirth)",fn:function(data){return [(!_vm.isEmpty(data.item))?_c('span',{staticClass:"text-caption text-gray-dark"},[_vm._v(" "+_vm._s(_vm._f("date")(data.item.dateOfBirth,{isUTC: false}))+" ")]):_vm._e()]}},{key:"cell(surgeon_name)",fn:function(ref){
                var item = ref.item;
return [(!_vm.isEmpty(item))?_c('div',{staticClass:"text-caption heavy d-flex align-items-start"},[_vm._v(" "+_vm._s(_vm.surgeonIdNameFormat( item.ocosDoctorId, ((item.doctorLastName) + ", " + (item.doctorFirstName)), item.doctorActive ))+" ")]):_vm._e()]}}])}),(_vm.loading)?_c('SpinnerOverlay',{attrs:{"position":"absolute"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }